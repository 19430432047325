import React, { useEffect, useState, useRef } from "react";
import {urlRedirect, urlSnapPayGetRequestID, urlSnapPayiFrame, urlPaymentStatus} from "../urls/urls";

const SnapPayPage = ({ isOpen, closeModal, student, snapPayLineItems, cart, balance }) => {
    const [requestId, setRequestId] = useState(null);
    const [isRedirected, setIsRedirected] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [modalHeight, setModalHeight] = useState('auto'); // State to store modal height
    const iframeRef = useRef(null); // Reference for iframe
    const modalRef = useRef(null); // Reference for modal container
    console.log(student);
    console.log(cart);

    useEffect(() => {
        if (isOpen) {
            // Start polling for redirect status when the modal opens
            setLoading(true);
        }

        fetchRequestId(jsonforSnapPay);
    }, [isOpen]);

        const pollForRedirectStatus = () => {
            const interval = setInterval(() => {
                // Make a request to your server to check if SnapPay has completed the payment
                fetch(`${urlPaymentStatus}`)
                    .then(response => response.json())
                    .then(data => {
                        if (data.paymentStatus === 'success') {
                            setIsRedirected(true); // Payment successful
                            setLoading(false);
                            clearInterval(interval); // Stop polling
                            closeModal(); // Close the modal after payment
                        } else if (data.paymentStatus === 'failure') {
                            setLoading(false);
                            clearInterval(interval); // Stop polling
                            alert('Payment failed, please try again.');
                        }
                    })
                    .catch(error => {
                        console.error('Error checking payment status:', error);
                        setLoading(false);
                        clearInterval(interval);
                    });
            }, 5000); // Poll every 5 seconds
        };

        const fetchRequestId = async (json) => {
            try {
                const response = await fetch(urlSnapPayGetRequestID, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(json),
                });

                if (!response.ok) throw new Error("Failed to fetch RequestID");

                const data = await response.json();
                setRequestId(data.requestid);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        let jsonforSnapPay = {
            accountid: window.env['SNAP_PAY_AccountID'],
            companycode: window.env["SNAP_PAY_CompanyCode"],
            currencycode: window.env["SNAP_PAY_CurrencyCode"],
            userid: window.env["SNAP_PAY_AccountID"],
            customerid: student.idStudent,
            transactiontype: "S", //S = Sale
            paymentmethod: "CC", //CC = Credit Card
            redirecturl: urlRedirect,
            transactionamount: balance,
            openiniFrame: "N",
            sendemailreceipts: "Y",

            // customer: {
            //     customername: student.name,
            //     addressline1: student.addressline1,
            //     city: student.city,
            //     state: student.state,
            //     zipcode: student.zipcode,
            //     country: student.country,
            //     phone: student.phone,
            //     email: student.email
            // },
            snappayLineItems: snapPayLineItems
        }

        if (isOpen && iframeRef.current) {
            // Adjust modal size when iframe content loads
            const handleIframeLoad = () => {
                // Dynamically set modal height based on iframe content
                if (iframeRef.current) {
                    const iframeDocument = iframeRef.current.contentWindow.document;
                    const iframeHeight = iframeDocument.body.scrollHeight;
                    setModalHeight(iframeHeight + 100); // Adjust modal height to iframe content height
                }
            };

            // Add load event listener to iframe
            iframeRef.current.addEventListener('load', handleIframeLoad);

            // Cleanup event listener on component unmount
            return () => {
                iframeRef.current.removeEventListener('load', handleIframeLoad);
            };
        }
    const handleProceedToPayment = () => {
        // Open SnapPay URL in a new tab
        window.open(`${urlSnapPayiFrame}${requestId}`, '_blank');
        // Check for redirect status periodically
        pollForRedirectStatus();
    };


if (!isOpen) return null;
    return (
            <div
                ref={modalRef}
                style={{
                    ...styles.modalContainer,
                    height: modalHeight !== 'auto' ? `${modalHeight}px` : 'auto', // Set modal height dynamically
                }}
            >
                <h2 className="text-xl font-semibold mb-4">SnapPay Checkout</h2>
                <p style={styles.modalContent}>
                    You will be redirected to the SnapPay payment window for completing the payment process. Please
                    review your order before proceeding.
                </p>
                <div style={styles.buttonsContainer}>
                    {loading ? (
                        <p style={styles.loadingText}>Please wait, you are being redirected to payment...</p>
                    ) : (
                        <button onClick={handleProceedToPayment} style={styles.proceedButton}>
                            Proceed to Payment
                        </button>
                    )}
                    <button onClick={closeModal} style={styles.closeButton}>Close</button>
                </div>
                {/*{loading ? (*/}
                {/*    <div className="loader">Loading...</div>*/}
                {/*) : error ? (*/}
                {/*    <p className="text-red-500">{error}</p>*/}
                {/*) : (*/}
                {/*    <iframe*/}
                {/*        src={`${urlSnapPayiFrame}${requestId}`}*/}
                {/*        className="w-full h-96 border rounded-lg"*/}
                {/*        title="SnapPay Checkout"*/}
                {/*        style={styles.iframe}*/}
                {/*        frameBorder="0"*/}
                {/*    />*/}
                {/*)}*/}
            </div>
    );
};

const styles = {
    modalBackdrop: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
        maxWidth: '50%',
        maxHeight: '80%',
    },
    modalContainer: {
        backgroundColor: 'white',
            padding: '20px',
            borderRadius: '10px',
            width: '80%', // Set the width to be a percentage (80% of the screen width)
            maxWidth: '900px', // Maximum width of the modal
            height: 'auto', // Height will adjust to the iframe's content
            textAlign: 'center',
            overflow: 'hidden', // Prevent overflow if iframe grows
            display: 'flex',
            flexDirection: 'column',
        },
            iframeContainer: {
            position: 'relative',
            width: '100%',
            height: 'inherit',
            borderRadius: '10px', // Optional rounded corners to match the modal
            marginBottom: '20px',
            overflow: 'hidden', // Ensure no scrolling within the container
        },
            iframe: {
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            border: 'none', // Removes default border around iframe
            borderRadius: '10px', // Optional: Matches the modal's rounded edges
        },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    proceedButton: {
        padding: '10px 20px',
        backgroundColor: '#28a745', // Green for "Proceed"
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        flex: '1',
        marginRight: '10px',
    },
    closeButton: {
        padding: '10px 20px',
        backgroundColor: '#dc3545', // Red for "Close"
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        flex: '1',
    },
    loadingText: {
        marginBottom: '20px',
        fontSize: '16px',
        color: '#666',
    },
        };


            export default SnapPayPage;
