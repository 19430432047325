
import { useState, useEffect} from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { HashRouter } from "react-router-dom";


import Products from "./Products";
import ProductCart from "./ProductCart";

const SHIRT_ID = "FT0004"

const ProductsCartParent = (props) => {

    const [products, setProducts] = useState([]); //array thats stores the data called from database
    const [isSelected, setIsSelected] = useState({});
    const [cart, setCart] = useState({}); //object that stores the keys of the items that have been selected
    const [snappayLineItems, setSnapPayLineItems] = useState([]);
    const [selectedArray, setSelectedArray] = useState([]);
    const [size, setSize] = useState('M');
    const [balance, setBalance] = useState(0);
    const [openWarningModal, setOpenWarningModal] = useState(false);

    function handleCartAddition(ProductObject) {

        let newCart = {...cart};
        let newsnappayLineItems = [...snappayLineItems];
        for(let key of Object.keys(ProductObject)) {
            if(key in newCart){
                /* should not happen but in case it does, do nothing */
            }
            else {

                let newProduct = {[key]: ProductObject[key]}
                newCart = {
                    ...newCart,
                    ...newProduct
                }
                console.log(`new cart method of adding ${newProduct}`)
                console.log(newProduct)

            }

            if(key === SHIRT_ID) {
                newCart[key]['size'] = size;
                console.log(newCart);
            }
        }
        setCart({...newCart})
        moveToSelectedArray(newCart)

    }

    function handleCartRemoval(id) {
        let newCart = {...cart};
        if(id in cart) {
            delete newCart[id]
            setCart((currCart) => {
                return {...newCart}
            })
        }
    }

    function updateTotals(listObject) {
        let total = 0;
            console.log("TEST");
        for(let value of Object.values(listObject)) {
            console.log("TEST");
            console.log(value);
            total += value.fieldData.Unit_Price_NUMBER;
        }
        setBalance(total);
    }

    function moveToSelectedArray(cart) {
        console.log('move to selected array')

        console.log(cart);
        console.log(products.length);
        let newSelectedArray = [];
        for(let i = 0; i < products.length ; i++) {

            console.log(i);
            console.log(products[i].fieldData)
            if(products[i].fieldData._ID_Readable in cart)
            {
                if(products[i].fieldData._ID_Readable === SHIRT_ID){
                    products[i]['size'] = cart[SHIRT_ID]['size'];
                }
                console.log(newSelectedArray)
                newSelectedArray.push(products[i])
            }
        }

        //new selected array now has all the selected items in it. sort by date
        //and make sure the ones with no date end up at the bottom

        newSelectedArray.sort((a,b) => {
            let dateA, dateB;
            if(a.fieldData.Date_Event === "") {

                let dateA = new Date("01/01/2070")
            }
            else {

                let dateA = new Date(a.fieldData.Date_Event)
            }
            if(b.fieldData.Date_Event === "") {

                let dateB = new Date("01/01/2070")
            }
            else {

                let dateB = new Date(a.fieldData.Date_Event)
            }

            return dateA - dateB
 
            
        })
        console.log(newSelectedArray)

        setSelectedArray((currArray) => [...newSelectedArray]);

        console.log('end move to selected array')

    }

    return (
        <>
            <Route path="/products">
                <Products
                    student={props.student}
                    products={products}
                    isSelected={isSelected}
                    setProducts={setProducts}
                    setIsSelected={setIsSelected}
                    handleCartAddition={handleCartAddition}
                    cart={cart}
                    setSize={setSize}
                    size={size}
                    balance={balance}
                    updateTotals={updateTotals}
                    setflagErrorMessage={props.setflagErrorMessage}
                    setOpenWarningModal={setOpenWarningModal}
                    moveToSelectedArray={moveToSelectedArray}
                    >

                </Products>
            </Route>
            <Route path="/productCart">
                <ProductCart
                    student={props.student}
                    products={products} //arrya that stores all objects
                    setProducts={setProducts}
                    isSelected={isSelected} //object that stores what is selected
                    setIsSelected={setIsSelected} //function to handle ui selection
                    handleCartAddition={handleCartAddition} //function to add to cart
                    handleCartRemoval={handleCartRemoval} //function to remove from cart
                    setCart={setCart}
                    cart={cart}
                    setSize={setSize}
                    size={size}
                    balance={balance}
                    setBalance={props.setBalance}
                    updateTotals={updateTotals}
                    setShowMessage={props.setShowMessage}
                    setMessage={props.setMessage}
                    showMessage={props.showMessage}
                    message={props.message}
                    setFlagErrorMessage={props.setFlagErrorMessage}
                    setOpenWarningModal={setOpenWarningModal}
                    openWarningModal={openWarningModal}
                    moveToSelectedArray={moveToSelectedArray}
                    selectedArray={selectedArray}
                />
            </Route>

        </>


    )

}


export default ProductsCartParent