import React, { useState, useEffect } from "react";
import env from "react-dotenv";
import CurrencyFormat from 'react-currency-format';
import CurrencyInput from "react-currency-input-field";
import Cleave from 'cleave.js/react';
import { Redirect, Link } from "react-router-dom";
import { Button } from "@mui/material";

import PaymentModal from './PaymentModal';
import { CheckFooter } from '../components/CheckFooter';
import { AmountDueSingle } from "../components/AmountDueSingle";
import InputField from "../components/InputFields";
import {handleDeposit, handlePaymentTypes, handleSecondCharge} from "./paymentHandleDeposit";
import ModalDone  from './ModalDone';
import { ImageUploadSubmitButton } from "../components/ImageUploadSubmitButton";
import { PaymentForm, CreditCard, CreditCardFunctionChildrenProps, ApplePay, GooglePay} from "react-square-web-payments-sdk";
// import { CreditCardProps, CreditCardBase, CreditCardChildren, CreditCardFunctionChildren,CreditCardPayButtonProps } from 'react-square-web-payments-sdk/dist/components/credit-card/credit-card.styles';

// CSS
import "../App.scss";
import "./Payment.scss";

// images
import visa from "../images/visa.png";
import mastercard from "../images/mastercard.png";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Payment from "./Payment";
import { getActiveProducts } from "../images/API/httpProducts";

// urls to sales (this is what makes a invoice record and validates CC)
import {
    urlSales,
    urlGetFieldTrips,
    urlSnapPayBase,
    urlSnapPayiFrame,
    urlRedirect,
    urlSnapPayGetRequestID
} from "../urls/urls";
import products from "./Products";
import AddIcon from "@mui/icons-material/Add";
import {AmountDue} from "../components/AmountDue";

//Local
// const url = "http://localhost:63342/csssa/ccSales.php";
// const urlGetFieldTrips = "http://localhost:63342/csssa/getFieldTrips.php";

//TEST SERVER
// const url = "https://payments.csssa.org/ccSales.php"
// const urlGetFieldTrips = "https://payments.csssa.org/getFieldTrips.php";

//LIVE SERVER
// const urlSales = "https://preregistration.csssa.org/ccSales.php"
// const urlGetFieldTrips = "https://preregistration.csssa.org/getFieldTrips.php";


const PaymentSingle = (props) => {
    // const [paymentType, setPaymentType] = useState(); // variable for payment type
    const [paymentConfirmed, setPaymentConfirmed] = useState(false) //variable for successful payment or not
    const [counter, setCounter] = useState(0) //variable for successful payment or not

    /* ERROR OBJECTS DEPENDING ON WHAT WENT WRONG */
    const [error, setError] = useState({});
    const [cvvError, setCvvError] = useState({}); //variable for cvv errors 
    const [cardHolderNameError, setCardHolderNameError] = useState({}); //variable for cvv errors 
    const [cardNumberError, setCardNumberError] = useState({}); //variable for cvv errors 
    const [expirationError, setExpirationError] = useState({}); //variable for cvv errors 
    const [amountError, setAmountError] = useState({}); //variable for cvv errors 
    /* END OF ERROR OBJECT STATE VARIABLES */

    const [isFormReady, setIsFormReady] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // const squareButtonProps: ;
    //     squareButtonProps = {
    //     buttonProps: {isLoading : isLoading}
    // }

    const squareButtonProps = {isLoading: isLoading, }

    // State variable for amount due
    const [amountDue, setAmountDue] = useState(props.balance);

    /* STATE VARIABLES FOR DEPOSIT AND TUITION */
    const [balance, setBalance] = useState(props.balance)

    // Session storage for student
    // const studentStorage = window.sessionStorage;

    // test for empty student object
    const emptyStudentObject = false//Object.keys(props.student).length === 0;

    // test for empty session object
    const emptySessionObject = sessionStorage.length === 0;
            // console.log(`this is the the sessions object ${sessionStorage}`);

    useEffect(() => {

        // student object is NOT empty and session object is empty
        if (emptySessionObject) {
            // console.log(`error this is the the sessions object ${sessionStorage}`);
            return ( <Redirect to='/' />)

        }
        let jsonData = {
            idStudent: sessionStorage.idStudent
        };
        jsonData = JSON.stringify(jsonData);
        // console.log(`this is the useRef the data is: ${jsonData}`);
        //post request to server . fetch returns a promise

        const getRequestID = async () => {

            /*format json */
            let jsonforSnapPay = {
                accountid: process.env["SNAP_PAY_AccountID"],
                companycode: process.env["SNAP_PAY_CompanyCode"],
                currencycode: process.env["SNAP_PAY_CurrencyCode"],
                userid: process.env["SNAP_PAY_AccountID"],
                transactiontype: "S", //S = Sale
                paymentmethod: "CC", //CC = Credit Card
                redirecturl: urlRedirect,
                transactionamount: props.balance,
                openiniFrame: "Y",
                sendemailreceipts: "Y",

                customer: {
                    customername: props.student.name,
                    addressline1: props.student.addressline1,
                    city: props.student.city,
                    state: props.student.state,
                    zipcode: props.student.zipcode,
                    country: props.student.country,
                    phone: props.student.phone,
                    email: props.student.email
                },
                snappayLineItems: props.snappayLineItems
            }

            let jsonData = JSON.stringify(jsonforSnapPay);
            console.log('fetching');
            try {
                const response = await fetch(urlSnapPayGetRequestID, {
                    method: "POST",
                    body: jsonData,
                    headers: {
                        "Content-Type": "application/json",
                    }
                })

                if (!response.ok) throw new Error("Failed to fetch RequestID.")
                const data = await response.json();
                setRequestID(data.requestid);
            } catch (err) {
                setError(err.message);
            } finally {
                setIsLoading(false);
            }

        };

        getRequestID();

    }, []);

    // every payment starts off with this object
    const defaultPaymentObject = {
        amount: props.amount,
        creditCardNumber: "",
        expiration: "",
        CVV: "",
        cardHolderName: "",
        ccType: "",
        idStudent: props.student.idStudent,
        paymentType: 'tuition',
        key: counter ,
        ccToken: "",
        ccLast4: ""
    };


    const [payment, setPayment] = useState(defaultPaymentObject); // array of payment objects for the data of each payment form

    const [requestID, setRequestID] = useState("");

    const [isCheckoutOpen, setCheckoutOpen] = useState(false);

    const openCheckoutModal = () => {
        setCheckoutOpen(true);
    };

    const closeCheckoutModal = () => {
        setCheckoutOpen(false);
    };


    function clearErrors() {
        if(error.error){
            setError({error: false});
        }
        if(expirationError.error){
            setExpirationError({error: false});
        }
        if(cardHolderNameError.error){
            setCardHolderNameError({error: false});
        }
        if(cvvError.error){
            setCvvError({error: false});
        }
        if(cardNumberError.error) {
            setCardNumberError({error: false});
        }
        if(amountError.error){
            setAmountError({error: false});
        }
    }

    const handleAmountChange = (value) => {

        /* Theres something going on here where this handlers is running
         * too many times. theres times where its running with undefined values
         * im thinking that the component add the currency sign which causes the onchange 
         * event to trigger which cause this function to run. easy and dirty fix is to pass
         * a flag that is set only when we change the value and when the component
         * auto adds the currency sign it wont have a value so we can ignore and not run
         * the rest of the code 
         */

        // console.log(`the value for amount event change is: ${value} and the index is: ${index}`)
        // sum of every payment amount added together
        // let totalAmount = 0;
        // let depositAmount = props.student.depositDue;
        // let balanceAmount = props.student.totalDue - depositAmount;

        //     // convert from string to int
        //     let currentAmount = parseInt(payment.amount.replace(/[$,]/g, ''));

        //     // if current amount is a number
        //     if (!isNaN(currentAmount)) {
        //         console.log("current amount: ", currentAmount);
        //         // add each amount to total amount
        //         totalAmount += currentAmount;
        //     }
        // // console.log(`index is: ${index}`);
        // // console.log(paymentsArray);

        // let remainderOfDeposit = totalAmount - depositAmount
        // // console.log(remainderOfDeposit)
        // if(remainderOfDeposit > 0){
        //     setDeposit(0);
        //     setBalance(balanceAmount - remainderOfDeposit)
        // }
        // else if(remainderOfDeposit <= 0) {
        //     setDeposit(depositAmount - totalAmount);
        //     setBalance(balanceAmount);
        // }
        // else {
        //     setDeposit(depositAmount - totalAmount);
        //     setBalance(balanceAmount);
        // }

        // if(totalAmount > (depositAmount + balanceAmount)) {
        //     setIsFormReady(false);
        // }


    };

    // handles a change to the form's input fields
    const handleFieldChange = (e, key) => {
        // if the amount field is being changed
        // if (key === "amount") {
        //     handleAmountChange(e.target.value.replace(/[$,]/g, ""));

        //     // remove $ and commas from amount before it goes into payments array
        //     payment[key] = e.target.value.replace(/[$,]/g, "");
        //     if(amountError.error){
        //         setAmountError({error: false});
        //     }
        // } 
        
        // // if the cc number field is being changed
         if (key === "creditCardNumber") {
             console.log(`in field change`)
            // remove spaces from cc number before it goes into payments array
            payment[key] = e.target.value.replace(/\s/g, "");
            if(cardNumberError.error) {
                setCardNumberError({error: false});
            }
        } 
        
        // // otherwise set value in payments array normally
        else {
            payment[key] = e.target.value; // change value in the paymentsArray

            if(key === "expiration" && expirationError.error){
                setExpirationError({error: false});
            }
            if(key === "cardHolderName" && cardHolderNameError.error){
                setCardHolderNameError({error: false});
            }
            if(key === "CVV" && cvvError.error){
                setCvvError({error: false});
            }
        }

        setPayment(payment); // set the state of the paymentsArray
        if(payment.amount !== "" && payment.CVV !== ""
        && payment.cardHolderName !== "" && payment.creditCardNumber !== ""
        && payment.expiration !== "")
        {
        }
        else {
            setIsFormReady(false); 
            return;
        }
        setIsFormReady(true);
    };

    // handle change amount when a payment is removed

    // const paymentSubmitHandler =  async (ev) => {
    const paymentSubmitHandler = (thePayment) => {
        // ev.preventDefault(); //vanilla js that prevents the browser from loading after submit
        // if(isFormReady === false) {
        //     return;
        // }
        let finalPayment = payment;
        setIsFormReady(false);
        setIsLoading(true);
        /* need to change data to send to filemaker, make a delimted list of id */
        let idLineItems = "";
        let size = "";
        for(let keys of Object.keys(props.cart)) {
            idLineItems = idLineItems + props.cart[keys].fieldData.ID_Field_Trip + "¶";
            console.log(keys);
            if("size" in props.cart[keys]) {
                size = props.cart[keys]['size']
            }
        }
        /*format json */
        let jsonForFilemaker = {
            idStudent: props.student.idStudent,
            idLineItems: idLineItems,
            Auto: 1,
            Amount: props.balance,
            // ccv: payment.CVV,
            // Expire: payment.expiration,
            // Name: payment.cardHolderName,
            // Number: payment.creditCardNumber,
            Type: "",
            idLineItemsArray: Object.keys(props.cart),
            Size: size,
            ccToken: thePayment.ccToken,
            ccLast4: thePayment.ccLast4,
            total: thePayment.amount,
            customerID: window.sessionStorage.squareCustomerID,
            flagPaid: 1,
            snappayLineItems: props.snappayLineItems
        }
        console.log('json: ', jsonForFilemaker);

        let jsonData = JSON.stringify(jsonForFilemaker);
        console.log('fetching');
        fetch(urlSales, {
            method: "POST",
            body: jsonData,
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json()) //.json returns a promise
            .then((body) => {
                console.log('in the body after fetch');
                console.log(body);

                if (body.success === true) {

                    props.setMessage('Thank you for your payment. You will receive a message with order details');
                    props.setShowMessage(true);
                    console.log('payment confirmed')
                    setPaymentConfirmed(true);
                    //setIsLoading(false);

                } else if (body.error === true) {
                    if(body.errorCVV) {
                        console.log('cvv error');
                        setCvvError(body.errorCVV);
                        props.setFlagErrorMessage(true);
                        props.setMessage(body.errorDescription);
                        props.setShowMessage(true);
                    }
                    else if(body.errorPostal) {
                        console.log('Postal error');
                        props.setFlagErrorMessage(true);
                        props.setMessage(body.errorDescription);
                        props.setShowMessage(true);
                    }
                    else if(body.errorDeclined) {
                        console.log('Declined error');
                        props.setFlagErrorMessage(true);
                        props.setMessage(body.errorDescription);
                        props.setShowMessage(true);
                    }
                    else if(body.errorGeneric) {
                        console.log('Generic error');
                        props.setFlagErrorMessage(true);
                        props.setMessage(body.errorDescription);
                        props.setShowMessage(true);
                    }
                    else if(body.errorCardHolderName) {
                        setCardHolderNameError(body);
                    }
                    else if(body.errorNumber) {
                        setCardNumberError(body);
                    }
                    else if(body.errorExpiration) {
                        setExpirationError(body);
                    }
                    else if(body.errorAmount) {
                        setAmountError(body)
                    }
                    else if(body.errorSoldOut) {
                        /* IF this happens the body will be composed of a couple of things 
                         * 1. productSoldOutID: id of the product that is sold out
                         * 
                         * products parent hold state of the product list so need to update that
                         * productsParent also holds the state of the Cart so remove product sold out from the id
                         * update the total
                         */
                        console.log('in the sold out')
                        let newProducts = getActiveProducts(urlGetFieldTrips).then() //this is an async function
                        let soldOutID = body['soldOutID'];
                        if(newProducts.error === true){
                            // return (<Redirect to='/' />)

                        }
                        console.log(body);
                        let name = props.cart[soldOutID]['fieldData']['Name']
                        props.setProducts(newProducts);
                        props.handleSelectionAction(body.soldOutID, "")

                        props.setFlagErrorMessage(true);
                        props.setMessage(name + " is sold out and has been removed from your cart");
                        props.setShowMessage(true);

                    }
                    else if(body.errorDatabase) {
                        props.setFlagErrorMessage(true);
                        props.setMessage('Something went wrong ');
                        props.setShowMessage(true);
                        // return (<Redirect to='/' />)

                    }
                    else if(body.errorMultipleInvoices) {
                        props.setFlagErrorMessage(true);
                        props.setMessage('You have already submitted an invoice. You must wait till registration to purchase anything else');
                        props.setShowMessage(true);
                        // return (<Redirect to='/' />)

                    }
                    else {
                        props.setFlagErrorMessage(true);
                        props.setMessage('Something went wrong ');
                        props.setShowMessage(true);
                        console.log(body);
                        // return (<Redirect to='/' />)

                    }
                    setIsFormReady(false);
                    setIsLoading(false);
                }
                else{
                    //setIsLoading(false);
                    setIsFormReady(false);
                    console.log(body);

                }
            })
            .catch( (error) => {
                //removed the spinning loader
                setIsLoading(false);
                setIsFormReady(false);
                props.setFlagErrorMessage(true);
                props.setMessage('Something went wrong ');
                props.setShowMessage(true);
                console.log(error);
                console.log("Error when fetching, catch statement");
                // return (<Redirect to='/' />)

            });
    };


    return (
        <div className="Payment">
            <div className="paymentContainer">
                <form onSubmit={paymentSubmitHandler}>


                    <div className="paymentContainer__header">
                        <AmountDueSingle
                            totalDue={props.balance}
                        />
                    </div>

                    <Button
                        onClick={openCheckoutModal}
                        variant="contained"
                        startIcon={<ShoppingCartIcon />}
                    >
                        Checkout
                    </Button>

                    {/* Modal with SnapPay checkout */}
                    {isCheckoutOpen && (
                        <div className="modal-overlay">
                            <div className="modal-content">
                                <PaymentModal isOpen={true} closeModal={closeCheckoutModal} student={props.student} snapPayLineItems={props.snappayLineItems} cart={props.cart} balance={props.balance}/>
                            </div>
                        </div>
                    )}

                    {/*<div className="snapPay">*/}
                    {/*    <h3>Snap Pay</h3>*/}
                    {/*    <iframe src={`${urlSnapPayiFrame}${requestID}`}></iframe>*/}
                    {/*</div>*/}


                    {/* Show these fields for credit card */}
                    {/*        <>*/}
                    {/*            <PaymentForm*/}
                    {/*                applicationId="sq0idp-1vAhDAcdNNcG9vQrA8PBOg"*/}
                    {/*                cardTokenizeResponseReceived={(token, verifiedBuyer) => {*/}
                    {/*                    let copyOfPayment = {...payment};*/}
                    {/*                    copyOfPayment['ccToken'] = token.token;*/}
                    {/*                    copyOfPayment['last4'] = token.details.card.last4;*/}
                    {/*                    setPayment(payment => ({*/}
                    {/*                        ...copyOfPayment*/}
                    {/*                    }));*/}
                    {/*                    console.log(copyOfPayment)*/}
                    {/*                    console.log('token:', token);*/}
                    {/*                    console.log('verifiedBuyer:', verifiedBuyer);*/}
                    {/*                    console.log(props.student)*/}
                    {/*                    paymentSubmitHandler(copyOfPayment);*/}
                    {/*                }}*/}
                    {/*                locationId="LQP2ZRYZYNZ24"*/}
                    {/*                createPaymentRequest={() => ({*/}
                    {/*                    countryCode: "US",*/}
                    {/*                    currencyCode: "USD",*/}
                    {/*                    lineItems: props.snappayLineItems,*/}
                    {/*                    // pending is only required if it's true.*/}
                    {/*                    total: {*/}
                    {/*                        amount: amountDue,*/}
                    {/*                        label: "Total",*/}
                    {/*                    },*/}
                    {/*                    intent: "CHARGE"*/}
                    {/*                })}*/}
                    {/*            >*/}
                    {/*                /!*<CreditCard />*!/*/}
                    {/*                <CreditCard*/}
                    {/*                      buttonProps = {squareButtonProps}*/}
                    {/*                />*/}
                    {/*                /!*<GooglePay />*!/*/}
                    {/*            </PaymentForm>*/}
                    {/*        </>*/}
                </form>
            </div>
            {paymentConfirmed && <Redirect to="/thankyou"></Redirect>}

            <Link to="/dashboard">
                <FontAwesomeIcon icon={faHouse} className="homeButton"/>
            </Link>
        </div>
    );
};

export default PaymentSingle;
