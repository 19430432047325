
//LOCAL
// export const urlFileUpload = "http://localhost:8888/fileUpload.php";
// export const urlValidate = "http://localhost:8888/validate.php";
// export const urlValidationCC = "http://localhost:8888/ccValidate.php";
// export const urlGetStudent = "http://localhost:8888/getPaymentInfo.php";
// export const urlSales = "http://localhost:8888/ccSales.php";
// export const urlGetFieldTrips = "http://localhost:8888/getFieldTrips.php";
// export const urlFindSale = "http://localhost:8888/findSaleForStudent.php";
// export const urlPref = "http://localhost:8888/getPref.php";

// TEST SERVER
// export const urlFileUpload = "https://payments.csssa.org/fileUpload.php"
// export const urlValidate = "https://payments.csssa.org/validate.php"
// export const urlValidationCC = "https://payments.csssa.org/ccValidate.php"
// export const urlGetStudent = "https://payments.csssa.org/getPaymentInfo.php"
// export const urlSales = "https://payments.csssa.org/ccSales.php"
// export const urlGetFieldTrips = "https://payments.csssa.org/getFieldTrips.php";
// export const urlFindSale = "https://payments.csssa.org/findSaleForStudent.php"
// export const urlPref = "https://payments.csssa.org/getPref.php"

//LIVE SERVER
export const urlFileUpload = "https://preregistration.csssa.org/fileUpload.php"
export const urlValidate = "https://preregistration.csssa.org/validate.php"
export const urlValidationCC = "https://preregistration.csssa.org/ccValidate.php"
export const urlGetStudent = "https://preregistration.csssa.org/getPaymentInfo.php"
export const urlSales = "https://preregistration.csssa.org/ccSales.php"
export const urlGetFieldTrips = "https://preregistration.csssa.org/getFieldTrips.php";
export const urlFindSale = "https://preregistration.csssa.org/findSaleForStudent.php"
export const urlPref = "https://preregistration.csssa.org/getPref.php"
export const urlRedirect = "https://preregistration.csssa.org/ThankYou.php"
export const urlSnapPayBase = "https://stage.snappayglobal.com/"
export const urlSnapPayGetRequestID = "https://preregistration.csssa.org/getSnapPayRequestID.php"
export const urlSnapPayiFrame = `${urlSnapPayBase}Interop/InteropRequest/Index?ReqNo=`
export const urlPaymentStatus = "https://preregistration.csssa.org/PaymentStatus.php"
